// export const SERVER_API_URL = "https://javatest.mailstyle.net/tactcrm/";
// export const CLIENT_BASE_URL = "http://front.mailstyle.net";
// export const SERVER_BASE_URL = "https://javatest.mailstyle.net/tactcrm";
// export const ITEMS_PER_PAGE = 10;

export const SERVER_API_URL = "https://tactcrmback.digiapps.be/";
export const CLIENT_BASE_URL = "http://tactcrmfront.digiapps.be";
export const SERVER_BASE_URL = "https://tactcrmback.digiapps.be";
export const ITEMS_PER_PAGE = 10;

/*export const SERVER_API_URL = "http://localhost:8080/";
export const CLIENT_BASE_URL = "http://localhost:4200";
export const SERVER_BASE_URL = "http://localhost:8080";
export const ITEMS_PER_PAGE = 10;*/
