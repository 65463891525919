import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";

@Injectable()
export class LoginInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (request.url.endsWith("/authentication")) {
      return next.handle(request).pipe(
        tap((event) => {
          if (event instanceof HttpResponse) {
            const cookies = event.headers.getAll("Set-Cookie");
            // Traitez les cookies ici selon vos besoins
          }
        }),
        catchError((error: HttpErrorResponse) => {
          // Traitez les erreurs ici si nécessaire
          return throwError(error);
        })
      );
    }

    return next.handle(request);
  }
}
